import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '../../auth'
import { OrgScopeRequired, useOrgScope } from '../../org-scope'
import Helmet from 'react-helmet'
//import classnames from 'classnames'
import OrgScopeNavigator from '../../OrgScopeNavigator'
import { useParams, useHistory, useRouteMatch } from 'react-router-dom'
import {
  queryAllowDenyEntriesForScope,
  postAllowDenyEntry,
  deleteAllowDenyEntry,
  queryFilteredAllowDenyEntriesForScope,
} from '../../api'
import classnames from 'classnames'
import Choice from './Choice'
import Type from './Type'
import DateTime from '../../DateTime'
import { addEntryRequestIsWellFormed } from './validation'
import DownloadCSVLink from './DownloadCSVLink'

const AllowDeny = () => {
  const { scope } = useParams() // Scope that we're editing allow/deny for, e.g. merchant ID
  const routeMatch = useRouteMatch() // For relative path navigation
  const history = useHistory()
  const { token } = useAuth()
  const { tree } = useOrgScope()
  const [scopes, setScopes] = useState([])

  // Allow/Deny entries for this scope
  const recordsPerPage = 100
  const [entries, setEntries] = useState({ loading: false, failed: null, data: null })
  const [entriesList, setEntriesList] = useState({ loading: false, failed: null, data: null })
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(recordsPerPage)
  const [pageFilter, setPageFilter] = useState()
  const [activeFilter, setActiveFilter] = useState('')

  const typeSelectorFilter = useRef()
  const choiceSelectorFilter = useRef()
  const matchFilter = useRef()

  const fetchEntries = async () => {
    if (!scope) {
      return
    }

    if (!pageFilter) {
      setEntries({ ...entries, loading: true })
      setEntriesList({ ...entries, loading: true })

      setLoading(true)
      try {
        const data = await queryAllowDenyEntriesForScope(token, scope, pageCount)

        setEntries({ ...entries, loading: false, failed: null, data })
        setEntriesList({ ...entries, loading: false, failed: null, data })

        setLoading(false)
      } catch (failed) {
        setEntries({ loading: false, failed, data: null })
        setEntriesList({ loading: false, failed, data: null })
      }
    }
  }

  useEffect(() => {
    fetchEntries()
  }, [scope, pageCount])

  const reset = async () => {
    matchFilter.current.value = ''
    typeSelectorFilter.current.value = ''
    choiceSelectorFilter.current.value = ''

    setPageCount(recordsPerPage)
    setPageFilter()
    setActiveFilter('')

    //Calling this and not fetchEntries as the lifecycle breaks when calling fetchEntries here
    setEntries({ ...entries, loading: true })
    setEntriesList({ ...entries, loading: true })

    setLoading(true)
    try {
      const data = await queryAllowDenyEntriesForScope(token, scope, pageCount)

      setEntries({ ...entries, loading: false, failed: null, data })
      setEntriesList({ ...entries, loading: false, failed: null, data })

      setLoading(false)
    } catch (failed) {
      setEntries({ loading: false, failed, data: null })
      setEntriesList({ loading: false, failed, data: null })
    }
  }

  const filterTable = async (column, value) => {
    if (value.length <= 0) {
      setPageFilter()
      setActiveFilter('')
      setPageCount(recordsPerPage)
      fetchEntries()
    } else {
      var matched = ''
      var filterType = ''
      var choice = ''
      var filterQuery = ''

      if (column == 'match') {
        matched = value.toLowerCase()
        filterQuery = `match_cnts=${matched}`
      }

      if (column == 'type') {
        filterType = value.toLowerCase()
        filterQuery = `type=${filterType}`
      }

      if (column == 'choice') {
        choice = value.toLowerCase()
        filterQuery = `choice=${choice}`
      }

      setPageCount(pageCount + loadableCount)
      setPageFilter(true)
      setActiveFilter(filterQuery)
      setEntries({ ...entries, loading: true })
      setEntriesList({ ...entries, loading: true })

      setLoading(true)

      try {
        const data = await queryFilteredAllowDenyEntriesForScope(
          token,
          scope,
          filterQuery,
          pageCount
        )

        setEntries({ ...entries, loading: false, failed: null, data })
        setEntriesList({ ...entries, loading: false, failed: null, data })
        setLoading(false)
      } catch (failed) {
        setEntries({ loading: false, failed, data: null })
        setEntriesList({ loading: false, failed, data: null })
      }
    }
  }

  /* Navigate to a different org via URL change */
  const onOrgChange = (id) => {
    history.push(routeMatch.path.replace(':scope', id))
  }

  // Edit + post new entry
  const matchInputRef = useRef(null) // Ref to match text input field, so we can auto-focus it
  const [newEntry, setNewEntry] = useState({ saving: false, failed: null, req: {}, res: null })
  const onTypeChange = (type) => {
    setNewEntry({ ...newEntry, req: { ...newEntry?.req, type } })
    matchInputRef.current.focus() // Move cursor to text input
  }
  const onMatchChange = (e) =>
    setNewEntry({ ...newEntry, req: { ...newEntry?.req, match: e.target.value } })
  const onChoiceChange = (choice) => setNewEntry({ ...newEntry, req: { ...newEntry?.req, choice } })
  const postNewEntry = async () => {
    // TODO: If entry invalid, don't post
    setNewEntry({ ...newEntry, saving: true, failed: null, res: null })
    try {
      const res = await postAllowDenyEntry(token, scope, newEntry.req)
      setNewEntry({
        ...newEntry,
        res,
        saving: false,
        failed: null,
        // Reset match text, ready for entry of the next entry on the UI (same type, match)
        req: { ...newEntry?.req, match: '' },
      })
      // Refresh main list
      fetchEntries() // TODO: What about if we have filters?
    } catch (failed) {
      setNewEntry({ ...newEntry, failed, res: null, saving: false })
    }
  }
  const postNewEntryOnEnter = (e) => {
    if (e.key === 'Enter' && addEntryRequestIsWellFormed(newEntry.req)) {
      postNewEntry()
    }
  }

  // Remove entry
  const [delEntry, setDelEntry] = useState({ saving: false, failed: null, req: {}, res: null })
  const deleteEntry = async (req) => {
    if (!window.confirm(`Are you sure you want to remove ${req?.match} ?`)) {
      return
    }
    setDelEntry({ req, saving: true, failed: null, res: null })
    try {
      const res = await deleteAllowDenyEntry(token, req)
      setDelEntry({ saving: false, failed: null, res })
      // Refresh main list
      fetchEntries() // TODO: What about if we have filters?
    } catch (failed) {
      setDelEntry({ saving: false, failed, res: null })
      window.alert(`Something went wrong while trying to remove ${req?.match} - please try again.`)
    }
  }

  const showMore = async () => {
    setPageCount(pageCount + loadableCount)

    if (matchFilter.current.value != '') {
      filterTable('match', matchFilter.current.value)
    }
    if (typeSelectorFilter.current.value != '') {
      filterTable('type', typeSelectorFilter.current.value)
    }
    if (choiceSelectorFilter.current.value != '') {
      filterTable('choice', choiceSelectorFilter.current.value)
    }
  }

  useEffect(() => {
    if (tree) {
      Object.values(tree).forEach((item) => {
        if (item && typeof item === 'object' && 'name' in item && 'id' in item) {
          setScopes((current) => [...current, item])
        }
      })
    }
  }, [tree])

  const loadableCount = Math.min(
    recordsPerPage,
    entriesList?.data &&
      entriesList?.data.results &&
      entriesList?.data.results.length &&
      entriesList?.data.page &&
      entriesList?.data.page.total_count
      ? entriesList?.data.page.total_count - entriesList?.data.results.length
      : recordsPerPage
  )

  return (
    <OrgScopeRequired>
      <section className='allowdeny'>
        <Helmet>
          <title>Allow / Deny</title>
        </Helmet>
        <header>
          <div className='main'>
            <h1>Allow / Deny List </h1>
            <h4 className='contract-selector'>
              {scope != 'root' && (
                <select onChange={(e) => onOrgChange(e.target.value)}>
                  {scopes?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              )}
              <OrgScopeNavigator value={scope} onChange={onOrgChange} />
            </h4>
          </div>
          <div className='actions'>
            {entriesList?.data?.page && entriesList?.data?.page?.total_count > 0 && (
              <DownloadCSVLink
                displayCount={entriesList.data.page.total_count.toLocaleString()}
                filter={activeFilter}
                scope={scope}
              />
            )}
          </div>
        </header>
        <div className='body'>
          <header className='controls'>
            {entriesList && entriesList.data && entriesList.data.results && (
              <span className='summary'>
                Showing{' '}
                <strong className='count page_count'>{entriesList?.data?.results?.length}</strong>{' '}
                {entriesList?.data?.page &&
                  entriesList?.data?.results.length !== entriesList?.data?.page?.total_count && (
                    <span>
                      of
                      <strong className='count total_count'>
                        {entriesList.data.page.total_count.toLocaleString()}
                      </strong>
                    </span>
                  )}
                {entriesList?.loading && <span className='loading'>updating...</span>}
              </span>
            )}
          </header>
          {!entriesList.loading &&
            !entriesList.failed &&
            entriesList?.data?.page?.total_count === 0 && (
              <p className='none'>
                There are no <strong>allow/deny</strong> entries configured yet for this scope.
              </p>
            )}
          {entriesList.failed && (
            <p className='error'>
              Something went wrong while fetching entries for this scope. Please try again, or try
              navigating to a different scope.
            </p>
          )}
          <table style={{ marginLeft: '60px' }}>
            <thead>
              <tr>
                <th colSpan='5' align='left'>
                  <h3>Add new entry:</h3>
                </th>
              </tr>
              <tr>
                <th></th>
                <th className='type'>
                  <TypeSelector
                    value={newEntry?.req?.type || ''}
                    onChange={onTypeChange}
                    onKeyPress={postNewEntryOnEnter}
                  />
                </th>
                <th className='match'>
                  <input
                    ref={matchInputRef}
                    type='text'
                    value={newEntry?.req?.match || ''}
                    onChange={onMatchChange}
                    onKeyPress={postNewEntryOnEnter}
                  />
                </th>
                <th className='choice'>
                  <ChoiceSelector
                    value={newEntry?.req?.choice || ''}
                    onChange={onChoiceChange}
                    onKeyPress={postNewEntryOnEnter}
                  />
                </th>
                <th className='at'></th>
                <th className='actions'>
                  <button
                    onClick={postNewEntry}
                    disabled={newEntry?.saving || !addEntryRequestIsWellFormed(newEntry?.req)}
                  >
                    <i className='fas fa-plus' /> Add
                  </button>
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          <br />
          <br />
          <div style={{ backgroundColor: '#46ba3f', width: '100%', height: '4px' }} />
          <br />
          <br />
          <table style={{ marginLeft: '60px', width: '100%' }}>
            <tr>
              <td valign='top'>
                <button className='remove' title='Clear Filters' onClick={reset} disabled={false}>
                  {' '}
                  <i className='fas fa-undo-alt' />
                  {' Clear Filters'}
                </button>
              </td>
              <td></td>
              <td style={{ float: 'right', marginRight: '100px' }}>
                {entriesList?.data &&
                  entriesList?.data?.results.length !== entriesList?.data?.page.total_count && (
                    <>
                      <button onClick={() => showMore()} disabled={loading}>
                        {loading ? 'Loading...' : `Show ${loadableCount} more`}
                      </button>
                    </>
                  )}
              </td>
            </tr>
          </table>
          <br />
          <div>
            <table className='entries'>
              <thead>
                <tr>
                  <th className='num' align='left'>
                    #
                  </th>
                  <th className='type' align='left'>
                    <h4>Type</h4>
                    <select
                      ref={typeSelectorFilter}
                      onChange={(e) => filterTable('type', e.target.value)}
                    >
                      <option value=''>Type:</option>
                      <option value='email'>Email (customer)</option>
                      <option value='pan'>Card PAN</option>
                      <option value='cardid'>Card ID</option>
                      <option value='ip_cust'>IP (customer)</option>
                      <option value='cntry_bin'>Country (card issue)</option>
                      <option value='cntry_ip_cust'>Country (customer IP)</option>
                    </select>
                  </th>
                  <th className='match' align='left'>
                    <h4>Match</h4>
                    <input
                      ref={matchFilter}
                      type='text'
                      onKeyUp={(e) => filterTable('match', e.target.value)}
                      style={{ width: '170px' }}
                    />
                  </th>
                  <th className='choice' align='left'>
                    <h4>Setting</h4>
                    <select
                      ref={choiceSelectorFilter}
                      onChange={(e) => filterTable('choice', e.target.value)}
                    >
                      <option value=''>Allow/Deny</option>
                      <option value='a'>Allow</option>
                      <option value='d'>Deny</option>
                    </select>
                  </th>
                  <th className='at' align='left'>
                    Added at
                  </th>
                  <th className='actions' align='left'></th>
                  {/* TODO: `by` (person that added) */}
                </tr>
              </thead>
              <tbody>
                {entriesList.data?.results?.length > 0 &&
                  entriesList?.data?.results?.map((entry, n) => (
                    <tr
                      key={entry.match}
                      className={classnames({ [entry?.choice]: entry?.choice })}
                    >
                      <td className='num' align='left'>
                        {n + 1}
                      </td>
                      <td className={classnames({ type: true, [entry?.type]: entry?.type })}>
                        <Type {...entry} />
                      </td>
                      <td className='match' align='left'>
                        {entry.match}
                      </td>
                      <td className='choice' align='left'>
                        <Choice {...entry} />
                      </td>
                      <td className='at' align='left'>
                        <DateTime at={entry.at} />
                      </td>
                      <td className='actions' align='left'>
                        <button
                          className='remove'
                          title='Remove this entry'
                          onClick={() => deleteEntry(entry)}
                          disabled={delEntry?.req === entry}
                        >
                          {' '}
                          <i className='fas fa-trash-alt' />{' '}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <br />
          <br />
          {newEntry.failed && (
            <p className='error'>Something went wrong when adding that entry - please try again.</p>
          )}
          {/*
          <pre>{JSON.stringify(entries, null, 2)}</pre>
          */}
        </div>
        <br />
      </section>
    </OrgScopeRequired>
  )
}

const TypeSelector = ({ value, onChange }) => (
  <select value={value} onChange={(e) => onChange(e.target.value)}>
    <option value=''>Type:</option>
    <option value='email'>Email (customer)</option>
    <option value='pan'>Card PAN</option>
    <option value='cardid'>Card ID</option>
    <option value='ip_cust'>IP (customer)</option>
    <option value='cntry_bin'>Country (card issue)</option>
    <option value='cntry_ip_cust'>Country (customer IP)</option>
  </select>
)
TypeSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

const ChoiceSelector = ({ value, onChange }) => (
  <select value={value} onChange={(e) => onChange(e.target.value)}>
    <option value=''>Allow/Deny:</option>
    <option value='a'>Allow</option>
    <option value='d'>Deny</option>
  </select>
)
ChoiceSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default AllowDeny
