import { toPairs, sortBy, path, pathOr, reverse, compose, map, paths } from 'ramda'

// Functions for dealing with the statistics received from the transaction API,
// useful for UI components that render various aspects of them.

/** acceptancePercent returns a percentage (0.0-1.0) of
 * the acceptance rate implied in the given stat. */
export const acceptancePercent = ({ value = {} }) => {
  if (Object.keys(value).length === 0) {
    return null
  } else if (value == null || value.ok == null || value.ok + (value.debounce_failed ?? 0) == 0) {
    return 0
  }
  return (1 / Math.max(1, value.ok + (value.debounce_failed ?? 0))) * value.ok
}

/** Given a stats summary, produces a list of [key, count] pairs
 * sorted by transacion count, descending. */
export const statsSortedByCount = compose(
  reverse,
  map(paths([[0], [1, 'cnt_by_status', 'total'], [1, 'cnt_by_status']])),
  sortBy(pathOr(0, [1, 'cnt_by_status', 'total'])),
  toPairs
)

/** Given a stats summary, produces a list of [key, valueInEUR] pairs
 * sorted by EUR settlement amount, descending. */
export const statsSortedByValueSettledEUR = compose(
  // Behold, Haskell programmer
  reverse,
  map(paths([[0], [1, 'net_vol_by_settle_ccy', 'EUR'], [1, 'cnt_by_status']])),
  sortBy(pathOr(0, [1, 'net_vol_by_settle_ccy', 'EUR'])),
  toPairs
)

/** Returns true if any value in the given stats
 * contains a non-null `net_vol_by_settle_ccy.EUR` */
export const hasAnyValueSettledEUR = (stats) => {
  // Behold, Golang programmer
  if (!stats) {
    return false
  }
  for (const id in stats) {
    if (
      stats[id] &&
      stats[id].net_vol_by_settle_ccy &&
      stats[id].net_vol_by_settle_ccy.EUR != null
    ) {
      return true
    }
  }
  return false
}

/** Given a `stats_by_ip_cntry` produces a list of [Country, percent]] pairs
 * sorted by acceptance rate, descending*/
export const statsSortedByAcceptance = compose(
  // Behold, Haskell programmer
  reverse,
  map(([country, stat = {}]) => [
    country,
    acceptancePercent(stat.cnt_by_status),
    stat.cnt_by_status,
  ]),
  sortBy(compose(acceptancePercent, path([1, 'cnt_by_status']))),
  toPairs
)
