import PropTypes from 'prop-types'
import classnames from 'classnames'

// TODO: This will be replaced by an API call
export const types = {
  email: 'Email (customer)',
  pan: 'Card PAN',
  ip_cust: 'IP (customer)',
  cntry_bin: 'Country (card issue)',
  cntry_ip_cust: 'Country (customer IP)',
  cardid: 'Card ID',
}

/* Given a `type` for an allow/deny entry, renders it visually. */
const Type = ({ type }) => (
  <span className={classnames({ type: true, unknown: !type, [type]: type })}>
    {types[type] || '?'}
  </span>
)

Type.propTypes = {
  type: PropTypes.string,
}

export default Type
